import { createApp } from 'vue'
import App from './App.vue'
import router from './router/router'
import './style.css'
import AOS from 'aos'
import 'aos/dist/aos.css'
import PosthogPlugin from './plugin/posthog'


const app = createApp(App)

.use(PosthogPlugin)

.use(router)

app.mount('#app')

// Initialiseer AOS na de app is gemonteerd
AOS.init()
