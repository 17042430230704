
<script setup>
import { useHead } from '@vueuse/head'

useHead({
title: 'Online portal op maat.',
meta: [
   {name: 'description' , content: 'Kom je niet weg met een standaard pakket? Dan ben je bij ons op het juiste adres. Jouw maatwerk portal.'}
],
link: [
   { rel: 'canonical', href: 'https://swenssoftware.nl/diensten/online-portal' },
],
})
</script>

<template>
<div>
   <div class="container mx-auto grid lg:grid-cols-2 pt-52 pb-10 gap-10 lg:h-[65dvh]">
   
      <img data-aos="fade-right" data-aos-duration="1200" src="../assets/portalapplicatie.jpg" alt="maatwerk software portal maken" class="rounded-xl h-72 md:h-[60dvh] w-full">
   
      <div  data-aos="fade-left" data-aos-duration="1200" class="px-5 lg:px-0 text-donkerColor">
         <span class="text-orange-500 text-bold text-lg">Creating Smart Portal Solutions</span>
         <h2 class="md:py-5 py-3">Serviceverhogende Online Portalen</h2>
         <p class="lg:w-[80%]">Een online portal is een afgeschermde omgeving waarop gebruikers inloggen via een webbrowser. Het is de online deur tot een centrale informatie-omgeving. Ideaal om klanten, medewerkers, partners en leveranciers gecontroleerd toegang te geven tot je data. Denk aan o.a. bestelgegevens, facturen, documenten, dashboards en nog veel meer. Online portals maken samenwerken, communiceren, workflows en kennisdeling vele malen efficiënter.</p>
         <button  class="border-2 border-hoverColor shadow-lg hover:shadow-navbar p-2 md:px-5 my-5 rounded-full  hover:bg-hoverColor transition ease-in-out duration-500"><a class='text-textColor' href="/contact"> Contact  <i class="fa-solid fa-arrow-right"></i></a></button>
   
      </div>
   </div>


   <div class="container mx-auto grid lg:grid-cols-2 pt-20 md:pt-72 pb-10 gap-10 ">
      <div class=" grid place-content-center lg:w-[80%] px-5 lg:px-0 text-donkerColor">

         
         <h1 class="lg:w-[80%] mb-5">De verschillende typen online portals</h1>
         <p>Er bestaan talloze soorten online portals. Qua techniek komen ze aardig met elkaar overeen, maar de nuance zit vooral in het doel ervan: zo zal een portaal voor bijvoorbeeld klanten er anders uitzien dan een B2B-portal. Aan de hand van het doel, functie en gebruik, bouwt Swenssoftware portals die daarop aansluiten.</p>
      </div>

      <div class="grid lg:grid-cols-2 gap-5 grid-rows-2 justify-content-stretch pb-52">
         <div class="lg:h-[50dvh] bg-ligteColor rounded-xl text-center grid place-content-center p-5 drop-shadow-xl shadow-donkerColor" data-aos="fade-up"  data-aos-duration="500">
           <div class="text-black">
               
               <i class="fa-solid fa-users lg:text-2xl bg-donkerColor text-ligteColor h-10 w-10 lg:h-14 lg:w-14 p-5 rounded-full mb-5"></i>
               <h3 class="text-xl">Klantenportaal</h3>
               <p>Hiermee kunnen klanten allerlei zaken online met je bedrijf regelen. Denk aan het wijzigen van persoonlijke gegevens of het inzien van bestel- en factuurgegevens.</p>
           </div>

         </div>


         <div class="lg:h-[50dvh] bg-ligteColor rounded-xl text-center grid place-content-center p-5 drop-shadow-xl shadow-donkerColor"  data-aos="fade-up"  data-aos-duration="500">
            <div class="text-black">
               <i class="fa-solid fa-gears lg:text-2xl bg-donkerColor text-ligteColor h-10 w-10 lg:h-14 lg:w-14 p-5 rounded-full mb-5"></i>
               <h3 class="text-xl">Selfservice Portaal</h3>
               <p>Met een selfservice portaal kunnen gebruikers bij een organisatie of instantie zelf zaken regelen of aanvragen doen. Denk bijvoorbeeld aan het digitale gemeenteloket.</p>
            </div>
         </div>


         <div class="lg:h-[50dvh] bg-ligteColor rounded-xl text-center grid place-content-center p-5 drop-shadow-xl shadow-donkerColor" data-aos="fade-up" data-aos-duration="500">
            <div class="text-black">
               <i class="fa-solid fa-cart-shopping lg:text-2xl bg-donkerColor text-ligteColor w-10 h-10 lg:h-14 lg:w-14 p-5 rounded-full mb-5"></i>
               <h3 class="text-xl">B2B Portaal</h3>
               <p>Een B2B portaal geeft afnemers, leveranciers, partners of dealers 24/7 de mogelijkheid om diverse zaken met je bedrijf regelen en producten direct online te bestellen.</p>
            </div>
         </div>

         <div class="lg:h-[50dvh] bg-ligteColor rounded-xl text-center grid place-content-center p-5  drop-shadow-xl shadow-donkerColor" data-aos="fade-up" data-aos-duration="500">
            <div class="text-black">
               <i class="fa-solid fa-handshake lg:text-2xl bg-donkerColor text-ligteColor w-10 h-10 lg:h-14 lg:*:w-14 p-5 rounded-full mb-5"></i>
               <h3 class="text-xl">Dealerportaal</h3>
               <p>Een dealerportaal stroomlijnt de communicatie en samenwerking met je dealernetwerk. Het biedt ze toegang tot belangrijke informatie, middelen en tools.</p>
            </div>
         </div>
       
      </div>
   </div>


   <div class="w-[100vw]  h-full relative overflow-hidden">
  <!-- Video als achtergrond -->
  <video class="absolute top-0 left-0 w-full h-full object-cover hidden md:block" autoplay loop muted>
    <source src="../assets/video/7021889_Internet_Iot_1920x1080.mp4" type="video/mp4" />
    Je browser ondersteunt geen video.
  </video>

  <img src="../assets/headerimg.jpeg" alt="Swenssoftware software maatwerk" class="absolute top-0 left-0 w-full h-full object-cover block md:hidden">
  <!-- Gradient overlay -->
  <div class="absolute top-0 left-0 w-full h-full bg-gradient-to-tr from-donkerColor via-white to-ligteColor opacity-50"></div>

  <!-- Content (optioneel) -->
  <div class="z-10 relative text-donkerColor pt-12 ">
 
   <div class=" text-center">
      <h3 class="text-3xl">Voordelen van online portalen</h3>
   </div>

   <div class="md:container mx-auto grid lg:grid-cols-2 place-content-center  gap-10 p-10">
      <div class="bg-ligteColor text-center min-h-[300px] rounded-2xl py-5 px-10 " data-aos="fade-up-right">
           <h2 class="flex items-center justify-center pb-5"> <i class="fa-solid fa-crosshairs bg-donkerColor text-ligteColor w-6 h-6 p-3 rounded-full mr-5 "></i>Efficiëntie</h2>
           <p>Optimaliseer workflows door gebruikers toegang te geven tot je informatie-omgeving en zo zonder jouw tussenkomst zelf zaken te kunnen inzien, wijzigen of updaten.</p>
      </div>

      <div class=" bg-ligteColor text-center min-h-[300px] rounded-2xl py-5 px-10  " data-aos="fade-up-left">
         <h2 class="flex items-center justify-center"> <i class="fa-solid fa-gears  bg-donkerColor text-ligteColor w-6 h-6 p-3 rounded-full mr-5 "></i>Integratie</h2>
           <p>Integreer al je informatiebronnen en gebruik de portal als dé centrale online plek om gebruikers gecontroleerd toegang te geven tot je informatie-omgeving.</p>
        
      </div>
      <div class=" bg-ligteColor text-center min-h-[300px] rounded-2xl py-5 px-10 " data-aos="fade-up-right">
         <h2 class="flex items-center justify-center pb-5"> <i class="fa-solid fa-globe  bg-donkerColor text-ligteColor w-6 h-6 p-3 rounded-full mr-5 "></i>Toegankelijkheid</h2>
           <p>Portals bieden 24/7 service en bereikbaarheid. Door in te loggen via een webbrowser kunnen gebruikers overal ter wereld en op alle devices inloggen op jouw systemen.</p>
        
      </div>
      <div class=" bg-ligteColor text-center min-h-[300px] rounded-2xl py-5 px-10 " data-aos="fade-up-left">
         <h2 class="flex items-center justify-center pb-5"> <i class="fa-solid fa-handshake  bg-donkerColor text-ligteColor w-6 h-6 p-3 rounded-full mr-5 "></i> Samenwerken</h2>
           <p>Werk beter en efficiënter samen doordat je hele informatie-omgeving op één centrale plek toegankelijk is voor leveranciers, partners, dealers en interne afdelingen.</p>
        
      </div>
   </div>

   
  </div>
 
</div>


<div class="w-[100vw] lg:h-[100vh] h-full relative bg-donkerColor">
   
      <div class="w-screen md:absolute md:top-0">
           
            <div class="overflow-hidden pt-10 pb-16">
                <div class="inline-block animate-scroll">
                    <img src="../assets/logo.png" alt="maatwerk software portal maken" class='w-25 h-20 mx-20'>
                    <img src="../assets/Vite.svg" alt="maatwerk software portal maken" class='w-25 h-20 mx-20'>
                    <img src="../assets/tailwindcss.png" alt="maatwerk software portal maken" class='w-25 h-20 mx-20'>
                    <img src="../assets/firebase.webp" alt="maatwerk software portal maken" class='w-25 h-20  mx-20'>
                    <img src="../assets/GithubLogo.png" alt="maatwerk software portal maken" class='w-25 h-20 mx-20'>
                    <img src="../assets/nodeJS.png" alt="maatwerk software portal maken" class='w-25 h-20 mx-20'>
                    <img src="../assets/figmaAfbeelding.png" alt="maatwerk software portal maken"  class='w-25 h-20  mx-20'>
                    <img src="../assets/relumeAfbeelding.png" alt="maatwerk software portal maken"  class='w-25 h-20  mx-20'>
                    
                    <img src="../assets/logo.png" alt="maatwerk software portal maken"  class='w-25 h-20 mx-20'>
                    <img src="../assets/Vite.svg" alt="maatwerk software portal maken"  class='w-25 h-20 mx-20'>
                    <img src="../assets/tailwindcss.png" alt="maatwerk software portal maken"  class='w-25 h-20 mx-20'>
                    <img src="../assets/firebase.webp" alt="maatwerk software portal maken"  class='w-25 h-20 mx-20'>
                    <img src="../assets/GithubLogo.png" alt="maatwerk software portal maken" class='w-25 h-20 mx-20'>
                    <img src="../assets/nodeJS.png" alt="maatwerk software portal maken"  class='w-25 h-20  mx-20'>
                    <img src="../assets/figmaAfbeelding.png" alt="maatwerk software portal maken"  class='w-25 h-20  mx-20'>
                    <img src="../assets/relumeAfbeelding.png" alt="maatwerk software portal maken"  class='w-25 h-20  mx-20'>
                </div>
            </div>
        </div>
        <div class="pt-[8.2%] grid md:grid-cols-2  lg:container mx-auto relative">

            <div class="bg-hoverColor rounded-r-full relative xl:w-[80%] xl:h-[90%] mt-52 md:block hidden">
               <img src="../assets/KijkrechtsSwen.png" alt="Contact SwensSoftware" class="  absolute bottom-0 xl:-left-10">
            </div>

            <div class="gap-5 text-white px-5 lg:px-0 md:pt-28">
               <h1 class="pb-5">
                  Online Portaal laten maken?</h1>
               <p class="w-[70%] md:w-[90%] py-3 z-50">Met een online portaal investeer je in toekomstbestendige IT die je business naar een hoger niveau tilt. Wil jij ook een portaal laten maken? Dan zit je bij SwensSoftware goed. Als gespecialiseerde ontwikkelaar van online portalen beschikken we over de expertise en ervaring die nodig is om het portaal te maken. Bovendien zorgen we er ook altijd voor dat die nieuwe oplossing probleemloos integreert met al je andere systemen en programma’s. Meer weten? Vul dan het contactformulier in voor een vrijblijvende kennismaking!</p>
               <button class="border-2 border-hoverColor shadow-lg hover:shadow-navbar p-2 md:px-5 my-5 rounded-full  hover:bg-hoverColor transition ease-in-out duration-300"><router-link class='text-textColor' to="/Contact">Neem contact op <i class="fa-solid fa-arrow-right"></i></router-link></button>
          
            </div>

            <div class="absolute -bottom-[9rem] right-0 sm:pl-10 sm:bg-hoverColor rounded-l-full md:hidden sm:block z-10">
               <img src="../assets/LeunSwen.png" alt="Contact SwensSoftware" class="">
            </div>
            
         </div>
   

  
</div>

</div>
</template>


<style scoped>
@keyframes scrollanimatie {
      0% {
          transform: translateX(0%);
      }
      100% {
          transform: translateX(-100%);
      }
  }

  .animate-scroll {
          display: flex;
          white-space: nowrap;
          will-change: transform;
          animation: scrollanimatie 25s linear infinite;
  }

  .panel {
  will-change: transform; /* Pre-optimalisatie hint */
  transform: translateZ(0); /* Forceer hardwareversnelling */
}
</style>
