
<script setup>
import { useHead } from '@vueuse/head'

useHead({
title: 'Web applicatie op maat.',
meta: [
   {name: 'description' , content: 'Kom je niet weg met een standaard pakket? Dan ben je bij ons op het juiste adres. Jouw maatwerk web applications.'}
],
link: [
   { rel: 'canonical', href: 'https://swenssoftware.nl/diensten/web-applicatie' },
],
})
</script>

<template>
   <div>
   <div class="container mx-auto grid lg:grid-cols-2 pt-52 pb-10 gap-10 lg:h-[65dvh]">
   
      <img data-aos="fade-right" data-aos-duration="1200" src="../assets/webhosting.jpg" alt="WebApplicatie maken" class="rounded-xl h-72 md:h-[60dvh] w-full drop-shadow-lg">
   
      <div data-aos="fade-left" data-aos-duration="1200" class="px-5 lg:px-0 text-donkerColor">
         <span class="text-orange-500 text-bold text-lg">Creating Smart Web Solutions</span>
         <h2 class="md:py-5 py-3">Flexibele Webapplicaties</h2>
         <p class="lg:w-[80%] ">Web applicaties zijn programma’s die werken via je webbrowser. Waar je op een website met name informatie bekijkt, kun je op een webapplicatie niet alleen data inzien en oproepen, maar ook informatie aanpassen en toevoegen. Door in te loggen krijg je als gebruiker een persoonlijke omgeving met functionaliteiten en data die aan jou zijn toegewezen. Omdat dit al gauw complex kan worden, kun je bij SwensSoftware webapplicaties laten maken.</p>
         <button  class="border-2 border-hoverColor shadow-lg hover:shadow-navbar p-2 md:px-5 my-5 rounded-full  hover:bg-hoverColor transition ease-in-out duration-500"><a class='text-textColor' href="/contact"> Contact  <i class="fa-solid fa-arrow-right"></i></a></button>
   
      </div>
   </div>


   <div class="md:container md:mx-auto grid md:grid-cols-2 pt-20 md:pt-72 pb-10 gap-10 ">
      <div class=" grid place-content-center lg:w-[80%] px-5 lg:px-0 text-donkerColor">

         
         <h1 class="lg:w-[90%] mb-5 leading-10">De verschillende typen web applicaties</h1>
         <p>Webapplicaties zijn er in diverse soorten en maten. Ze kunnen een breed en divers scala aan functies vervullen. SwensSoftware helpt je graag met software te laten maken in de vorm van diverse soorten webapplicaties.</p>
      </div>

      <div class="grid lg:grid-cols-2 gap-5 grid-rows-2 justify-content-stretch pb-52">
         <div class="lg:h-[50dvh] bg-ligteColor rounded-xl text-center grid place-content-center p-5 drop-shadow-lg " data-aos="fade-up"  data-aos-duration="500">
           <div class="text-donkerColor">
               
               <i class="fa-solid fa-window-maximize text-md lg:text-2xl bg-donkerColor text-ligteColor h-10 w-10 lg:h-14 lg:w-14 p-5 rounded-full mb-5"></i>
               <h3 class="text-xl">Single Page Application (SPA)</h3>
               <p>Een single page applicatie (SPA), zoals WeTransfer, Google of Outlook, heeft maar één pagina en draait volledig in de browser.</p>
           </div>

         </div>
         
         <div class="lg:h-[50dvh] bg-ligteColor rounded-xl text-center grid place-content-center p-5 drop-shadow-lg"  data-aos="fade-up"  data-aos-duration="500">
            <div class="text-donkerColor">
               <i class="fa-solid fa-earth-europe lg:text-2xl bg-donkerColor text-ligteColor h-10 w-10 lg:h-14 lg:w-14 p-5 rounded-full mb-5"></i>
               <h3 class="text-xl">Online Platformen</h3>
               <p>Online platformen brengen meerdere partijen bij elkaar om bijvoorbeeld informatie te delen of vraag – en aanbod samen te brengen.</p>
               <router-link to="/diensten/online-platform" class="">  <i class="fa-solid fa-arrow-right-long fa-2xl border-black border-2 p-3 rounded-full hover:bg-hoverColor duration-300 transition mt-5"></i></router-link>
            </div>
         </div>


         <div class="lg:h-[50dvh] bg-ligteColor rounded-xl text-center grid place-content-center p-5  drop-shadow-lg" data-aos="fade-up" data-aos-duration="500">
            <div class="text-donkerColor">
               <i class="fa-solid fa-users-viewfinder lg:text-2xl bg-donkerColor text-ligteColor w-10 h-10 lg:h-14 lg:w-14 p-5 rounded-full mb-5"></i>
               <h3 class="text-xl">Online Portalen</h3>
               <p>Een online portaal, zoals een klantenportaal, dealerportaal, B2B-portaal of selfserviceportaal, geeft je via een browser beveiligde toegang tot data bij anderen.</p>
               <router-link to="/diensten/online-portal" class="">  <i class="fa-solid fa-arrow-right-long fa-2xl border-black border-2 p-3 rounded-full hover:bg-hoverColor duration-300 transition mt-5"></i></router-link>
            </div>
         </div>
       
      </div>
   </div>


   <div class="w-[100vw] lg:h-[100vh] h-full relative overflow-hidden ">
  <!-- Video als achtergrond -->
  <video class="absolute top-0 left-0 w-full h-full object-cover hidden md:block" autoplay loop muted>
    <source src="../assets/video/7021889_Internet_Iot_1920x1080.mp4" type="video/mp4" />
    Je browser ondersteunt geen video.
  </video>

  <img src="../assets/headerimg.jpeg" alt="Swenssoftware software maatwerk" class="absolute top-0 left-0 w-full h-full object-cover block md:hidden">
  <!-- Gradient overlay -->
  <div class="absolute top-0 left-0 w-full h-full bg-gradient-to-tr from-donkerColor via-white to-ligteColor opacity-50"></div>

  <!-- Content (optioneel) -->
  <div class="z-10 relative text-donkerColor pt-12 ">
 
   <div class=" text-center">
      <h3 class="text-3xl">Voordelen van webapplicaties</h3>
   </div>

   <div class="md:container mx-auto grid lg:grid-cols-2 place-content-center  gap-10 p-10">
      <div class="bg-ligteColor text-center min-h-[300px] rounded-2xl py-5 px-10 " data-aos="fade-up-right">
           <h2 class="flex items-center justify-center pb-5"> <i class="fa-solid fa-bolt bg-donkerColor text-ligteColor w-6 h-6 p-3 rounded-full mr-5 "></i>Efficiëntie</h2>
           <p>Werk sneller door repetitieve, manuele en tijdsintensieve taken te automatiseren. Hiermee versimpel je de workflows van medewerkers.</p>
      </div>

      <div class=" bg-ligteColor text-center min-h-[300px] rounded-2xl py-5 px-10  " data-aos="fade-up-left">
         <h2 class="flex items-center justify-center pb-5"> <i class="fa-solid fa-gear  bg-donkerColor text-ligteColor w-6 h-6 p-3 rounded-full mr-5 "></i>Integratie</h2>
           <p>Integreer al je informatiebronnen en gebruik een webapplicatie met een dashboard waarmee je alle systemen samenbrengt op één plek.</p>
        
      </div>
      <div class=" bg-ligteColor text-center min-h-[300px] rounded-2xl py-5 px-10 " data-aos="fade-up-right">
         <h2 class="flex items-center justify-center pb-5"> <i class="fa-solid fa-fingerprint  bg-donkerColor text-ligteColor w-6 h-6 p-3 rounded-full mr-5 "></i>Toegankelijkheid</h2>
           <p>Werk remote waar en wanneer je maar wilt. Met een webapplicatie kun je overal ter wereld via een browser inloggen op je systemen.</p>
        
      </div>
      <div class=" bg-ligteColor text-center min-h-[300px] rounded-2xl py-5 px-10 " data-aos="fade-up-left">
         <h2 class="flex items-center justify-center pb-5"> <i class="fa-solid fa-list-check  bg-donkerColor text-ligteColor w-6 h-6 p-3 rounded-full mr-5 "></i> Schaalbaarheid</h2>
           <p>Door gebruik te maken van microservices-architectuur en containerisatie, zijn webapplicaties gemakkelijk naar behoefte op te schalen.</p>
        
      </div>
   </div>

   
  </div>
 
</div>


<div class="w-[100vw] lg:h-[100vh] h-full relative bg-donkerColor">
   
      <div class="w-screen md:absolute md:top-0">
           
            <div class="overflow-hidden pt-10 pb-16">
                <div class="inline-block animate-scroll">
                    <img src="../assets/logo.png" alt="Maatwerk Software web applicatie" class='w-25 h-20 mx-20'>
                    <img src="../assets/Vite.svg" alt="Maatwerk Software web applicatie" class='w-25 h-20 mx-20'>
                    <img src="../assets/tailwindcss.png" alt="Maatwerk Software web applicatie" class='w-25 h-20 mx-20'>
                    <img src="../assets/firebase.webp" alt="Maatwerk Software web applicatie" class='w-25 h-20  mx-20'>
                    <img src="../assets/GithubLogo.png" alt="Maatwerk Software web applicatie" class='w-25 h-20 mx-20'>
                    <img src="../assets/nodeJS.png" alt="Maatwerk Software web applicatie" class='w-25 h-20 mx-20'>
                    <img src="../assets/figmaAfbeelding.png" alt="Maatwerk Software web applicatie"  class='w-25 h-20  mx-20'>
                    <img src="../assets/relumeAfbeelding.png" alt="Maatwerk Software web applicatie"  class='w-25 h-20  mx-20'>
                    
                    <img src="../assets/logo.png" alt="Maatwerk Software web applicatie"  class='w-25 h-20 mx-20'>
                    <img src="../assets/Vite.svg" alt="Maatwerk Software web applicatie"  class='w-25 h-20 mx-20'>
                    <img src="../assets/tailwindcss.png" alt="Maatwerk Software web applicatie"  class='w-25 h-20 mx-20'>
                    <img src="../assets/firebase.webp" alt="Maatwerk Software web applicatie"  class='w-25 h-20 mx-20'>
                    <img src="../assets/GithubLogo.png" alt="Maatwerk Software web applicatie" class='w-25 h-20 mx-20'>
                    <img src="../assets/nodeJS.png" alt="Maatwerk Software web applicatie"  class='w-25 h-20  mx-20'>
                    <img src="../assets/figmaAfbeelding.png" alt="Maatwerk Software web applicatie"  class='w-25 h-20  mx-20'>
                    <img src="../assets/relumeAfbeelding.png" alt="Maatwerk Software web applicatie"  class='w-25 h-20  mx-20'>
                </div>
            </div>
        </div>
         <div class="pt-[8.2%] grid md:grid-cols-2  lg:container mx-auto relative">

            <div class="bg-hoverColor rounded-r-full relative xl:w-[80%] xl:h-[90%] mt-52 md:block hidden">
               <img src="../assets/KijkrechtsSwen.png" alt="Contact SwensSoftware" class="  absolute bottom-0 xl:-left-10">
            </div>

            <div class="gap-5 text-white px-5 lg:px-0 md:pt-28">
               <h1 class="pb-5">Webapplicatie ontwikkelen?</h1>
               <p class="w-[70%] md:w-[90%] py-3 z-50">Wil je graag een applicatie ontwikkelen of op maat software laten maken of web apps laten ontwikkelen? Dan zit je bij SwensSoftware goed. Als webapplicatie-bouwer beschikken we over de expertise en ervaring die nodig is om de webapplicatie te maken die jouw business naar het volgende niveau tilt. Bovendien zorgen we er ook altijd voor dat die nieuwe oplossing probleemloos integreert met al je andere systemen en programma’s. Weten wat een applicatie ontwikkelen gaat kosten of wat er zoal mogelijk is binnen jouw budget? Vul dan het contactformulier in voor een vrijblijvende kennismaking!</p>
               <button class="border-2 border-hoverColor shadow-lg hover:shadow-navbar p-2 md:px-5 my-5 rounded-full  hover:bg-hoverColor transition ease-in-out duration-300"><router-link class='text-textColor' to="/Contact">Neem contact op <i class="fa-solid fa-arrow-right"></i></router-link></button>
          
            </div>
            <div class="absolute -bottom-[9rem] right-0 sm:pl-10 sm:bg-hoverColor rounded-l-full md:hidden block z-10">
               <img src="../assets/LeunSwen.png" alt="Contact SwensSoftware" class="">
            </div>
            
           
               
            
         </div>
   

  
</div>

  </div>
</template>


<style scoped>
@keyframes scrollanimatie {
      0% {
          transform: translateX(0%);
      }
      100% {
          transform: translateX(-100%);
      }
  }

  .animate-scroll {
          display: flex;
          white-space: nowrap;
          will-change: transform;
          animation: scrollanimatie 25s linear infinite;
  }

  .panel {
  will-change: transform; /* Pre-optimalisatie hint */
  transform: translateZ(0); /* Forceer hardwareversnelling */
}
</style>
