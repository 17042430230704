// ./plugin/posthog.js
import posthog from "posthog-js";

export default {
  install(app) {
    posthog.init('phc_b54eUQAphQnQVStZmLxgUMPcNB6CvNOSf9SES2aC0Qp', {
      api_host: 'https://eu.i.posthog.com',
      autocapture: false, // Optioneel: automatische tracking uitzetten
      capture_pageview: false // Geen automatische pageview tracking
    });

    // Voeg PostHog toe aan Vue's globale properties
    app.config.globalProperties.$posthog = posthog;

    // Maak ook injecteerbaar zodat `inject('$posthog')` werkt
    app.provide('$posthog', posthog);
  },
};