<template>
    <div
      ref="cursor"
      class="custom-cursor z-50"
      :style="cursorStyle"
    ></div>
  </template>
  
  <script setup>
  import { ref, onMounted, onUnmounted } from 'vue';
  
  const cursor = ref(null);
  const cursorX = ref(0);
  const cursorY = ref(0);
  
  const updateCursorPosition = (e) => {
    cursorX.value = e.clientX;
    cursorY.value = e.clientY;
    if (cursor.value) {
      cursor.value.style.left = `${cursorX.value}px`;
      cursor.value.style.top = `${cursorY.value}px`;
    }
  };
  
  onMounted(() => {
    window.addEventListener('mousemove', updateCursorPosition);
  });
  
  onUnmounted(() => {
    window.removeEventListener('mousemove', updateCursorPosition);
  });
  
  const cursorStyle = {
    position: 'fixed',
    width: '25px',
    height: '25px',
    backgroundColor: '#F1B24A',
    borderRadius: '50%',
    pointerEvents: 'none',
    transform: 'translate(-50%, -50%)',
    transition: 'transform 0.1s ease-out, background-color 0.2s ease-out',
  };
  </script>
  
  <style scoped>
  .custom-cursor {
    position: fixed;
    width: 20px;
    height: 20px;
    background-color: rgba(0, 0, 0, 0.5);
    border-radius: 50%;
    pointer-events: none;
    transform: translate(-50%, -50%);
    transition: transform 0.1s ease-out, background-color 0.2s ease-out;
  }
  </style>