<template>
  <nav class="z-50 sticky container mx-auto">
    <a href="/" class=""><img src="../assets/SwensSoftware LOGO_C2.png" alt="SwensSoftware maatwerk software" class="w-32 ml-5 mt-5 fixed" /></a>

    <div class="flex md:items-center  top-5 left-1/2 transform -translate-x-1/2 w-screen md:w-fit fixed">
      <div class="w-screen flex justify-end md:hidden pr-10">
        <!-- Mobile Menu Toggle Button -->
      <button
        @click="toggleMenu"
        class="md:hidden flex flex-col items-center bg-donkerColor duration-200 transition ease-in-out px-2 py-1 rounded-lg z-50 justify-items-end"
        :aria-expanded="open"
        aria-controls="mobile-menu"
      >
        <span class="w-6 h-0.5 bg-ligteColor my-1 rounded-xl" :class="open ? '!bg-hoverColor rounded-2xl duration-300 ease-in-out transform rotate-45 w-[29px] h-[2px] origin-top-left bg-DonkerColor' : 'rounded-2xl w-[26px] h-[2px] bg-DonkerColor transform duration-300'"></span>
        <span class="w-6 h-0.5 bg-ligteColor my-1 rounded-xl" :class="open ? 'w-0 h-0 opacity-0 transform ease-in duration-400 bg-DonkerColor' : ' rounded-2xl w-[26px] h-[2px] bg-DonkerColor transform duration-300'"></span>
        <span class="w-6 h-0.5 bg-ligteColor my-1 rounded-xl" :class="open ? '!bg-hoverColor  rounded-2xl duration-300 ease-in-out transform -rotate-45 w-[28.4px] h-[2px] origin-bottom-left bg-DonkerColor' : 'rounded-2xl w-[26px] h-[2px] bg-DonkerColor transform duration-300'"></span>
      </button>
      </div>

      <!-- Navigation Menu -->
      <ul id="mobile-menu" class="fixed -top-5 md:top-0 left-0 w-full md:bg-inherit bg-ligteColor transform transition-transform duration-300 ease-in-out md:relative md:flex md:h-auto md:w-auto md:items-center md:gap-16 md:transform-none" :class="{ '-translate-x-full': !open, '-translate-x-100': open }"
      >
        <li class="group">
          <router-link  @click="toggleMenu" to="/" class="block text-center text-[24px] py-2">Home     <span class="block max-w-0 group-hover:max-w-full transition-all duration-500 h-0.5 bg-hoverColor"></span></router-link>
        </li>
        <li class="relative group">
          <a class="block text-center text-[24px] cursor-pointer">Diensten</a>
          <ul
            class="absolute hidden group-hover:flex flex-col gap-2 bg-ligteColor p-4 rounded-xl w-[250px] ml-20 md:ml-0  "
          >
            <li class="group"><router-link  @click="toggleMenu" class="!text-[20px]" to="/diensten/web-applicatie">Web-applicatie  </router-link></li>
            <li class="group"><router-link  @click="toggleMenu" class="!text-[20px]" to="/diensten/app-applicatie">App-applicatie</router-link></li>
            <li class="group"><router-link  @click="toggleMenu" class="!text-[20px]" to="/diensten/online-portal">Portal Development</router-link></li>
            <li class="group"><router-link  @click="toggleMenu" class="!text-[20px]" to="/diensten/online-platform">Online Platform</router-link></li>
            <li class="group"><router-link  @click="toggleMenu" class="!text-[20px]" to="/diensten/api-koppeling">API-Koppelingen </router-link></li>
          </ul>
        </li>
        <li class="group">
          <router-link  @click="toggleMenu" to="/contact" class="block text-center text-[24px] py-2">Contact     <span class="block max-w-0 group-hover:max-w-full transition-all duration-500 h-0.5 bg-hoverColor"></span></router-link>
        </li>
        <li class="group">
          <router-link  @click="toggleMenu" to="/cases" class="block text-center text-[24px] py-2 ">Cases     <span class="block max-w-0 group-hover:max-w-full transition-all duration-500 h-0.5 bg-hoverColor"></span></router-link>
        </li>
      </ul>
    </div>
  </nav>
</template>

<script setup>
import { ref } from 'vue';

// Reactive state for menu
const open = ref(false);

// Toggle function for menu
const toggleMenu = () => {
  open.value = !open.value;
};
</script>



<style scoped>
/* Add smooth animation for the mobile menu */
ul {
  transition: transform 0.3s ease-in-out;
}

ul.hidden {
  display: none;
}

.group-hover > ul {
  display: flex;
}



</style>
