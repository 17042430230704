<template>
  <div>
    
    <headerSection />
    <cursor />
  <transition name="fadeCoockies">
    <coockiebanner v-if="showBanner" @hideBanner="showBanner = false" />
  </transition>

  <router-view />
  <footerSection />
  </div>
</template>

<script setup>
import { inject, ref, onMounted } from 'vue';

import cursor from './components/cursorComponent.vue';
import headerSection from './components/headerSection.vue';
import footerSection from './components/footerSection.vue';
import coockiebanner from './components/coockieSection.vue';

// Injecteer PostHog
const posthog = inject('$posthog');

const showBanner = ref(false);

onMounted(() => {
  if (posthog) {
    console.log("✅ PostHog is correct geladen.");
    const hasOptedIn = posthog.has_opted_in_capturing();
    const hasOptedOut = posthog.has_opted_out_capturing();
    showBanner.value = !(hasOptedIn || hasOptedOut);
  } else {
    console.error("❌ PostHog is niet beschikbaar in App.vue.");
    showBanner.value = true;
  }
});
</script>

<style scoped>
.fadeCoockies-enter-active,
.fadeCoockies-leave-active {
  transition: none;
}
.fadeCoockies-enter,
.fadeCoockies-leave-to {
  opacity: 0;
}
</style>
